main#single{
  >article{
    @include pc{
      width: 100%;
      max-width: 1024px;
      padding: 0 20px;
      margin-left: auto;
      margin-right: auto;
    }
    .columns{
      &:not(:first-of-type){
        @include pc{
          margin-top: 40px;
        }
        @include sp{
          margin-top: calc(40 / 375 * 100vw);
        }
      }
      .img{
        overflow: hidden;
      }//.img
    }//.columns
    .column1{
      .img{
        width: 100%;
      }
    }//.column1
    .column2{
      @include pc{
        display: flex;
        justify-content: space-between;
      }
      .img{
        @include pc{
          width: calc(50% - 20px);
        }
        &:not(:first-of-type){
          @include sp{
            margin-top: calc(40 / 375 * 100vw);
          }
        }
      }//.img
    }//.column2
  }//article
  .overviews{
    @include pc{
      width: 100%;
      max-width: 1024px;
      margin: 30px auto 0;
      padding: 0 20px;
    }
    @include sp{
      margin-top: calc(40 / 375 * 100vw);
      padding: 0 calc(15 / 375 *100vw);
    }
    >h1{
      line-height: 1.3em;
      @include pc{
        font-size: 10px;
      }
    }//h1
    >table{
      line-height: 1.3em;
      margin-top: 1em;
      text-align: left;
      vertical-align: top;
      @include pc{
        font-size: 10px;
      }
      @include sp{
        font-size: calc(10 / 375 * 100vw);
      }
      tr{
        th{
          @include pc{
            padding-right: 30px;
          }
          @include sp{
            padding-right: calc(30 / 375 * 100vw);
          }
        }//th
        &+tr{
          th,td{
            @include pc{
              padding-top: 4px;
            }
            @include sp{
              padding-top: calc(4 / 375 * 100vw);
            }
          }
        }
      }//tr
    }//table
  }//.overviews
}//main#single
