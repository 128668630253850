main#archive{
  >ul{
    @include pc{
      padding-left: 29px;
      padding-right: 30px;
      display: flex;
      flex-wrap: wrap;
    }
    >li{
      @include pc{
        width: calc(100% / 3);
        padding: 0 0 0 1px;
        margin-bottom: 80px;
      }
      &:not(:first-of-type){
        @include sp{
          margin-top: calc(50 / 375 * 100vw);
        }
      }
      &:hover{
        >a{
          .thumb-wrap{
            opacity: .5;
          }//.thumb-wrap
          >h2, h3, h4{
            opacity: .5;
          }
        }//a
      }//&:hover
      >a{
        .thumb-wrap{
          width: 100%;
          overflow: hidden;
          position: relative;
          opacity: 1;
          transition: opacity .15s ease;
          .thumb{
            width: 100%;
            height: 0;
            padding-bottom: calc(2 / 3 * 100%);
            background: no-repeat center/cover;
          }//.thumb
          .mosaic{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            >svg{
              width: 100%;
              height: auto;
              rect{
                opacity: 1;
                &.hide{
                  opacity: 0;
                }
              }//rect
            }//svg
          }//.mosaic
        }//.thumb-wrap
        >h2, h3, h4{
          opacity: 1;
          transition: opacity .15s ease;
        }
        >h2{
          line-height: 1em;
          @include pc{
            font-size: 18px;
            margin-top: 5px;
          }
          @include sp{
            margin: calc(5 / 375 * 100vw) calc(15 / 375 * 100vw) 0;
            font-size: calc(22 / 375 * 100vw);
          }
        }//h2
        >h3{
          line-height: 1em;
          color: #939393;
          @include pc{
            font-size: 18px;
          }
          @include sp{
            margin: 0 calc(15 / 375 * 100vw);
            font-size: calc(22 / 375 * 100vw);
          }
        }//h3
        >h4{
          line-height: 1em;
          color: #939393;
          @include pc{
            font-size: 10px;
            margin-top: 10px;
          }
          @include sp{
            margin: calc(10 / 375 * 100vw) calc(15 / 375 * 100vw) 0;
            font-size: calc(10 / 375 * 100vw);
          }
        }//h4
      }//a
    }//li
  }//ul
}//main#archive
