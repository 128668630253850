/*!
Theme Name: tm-inc
Description: tm-inc
*/
@import "reset";
@import "module";
@import "main";
@import "index";
@import "archive";
@import "single";
@import "about";
