$minWidth: 768px;
$maxWidth: 1440px;

$bp1: 1024px;
$bp2: 1280px;
//$bp3: 1600px;

*, *:before, *:after{
box-sizing: border-box;
}

body{
  font-size: 10px;
  a{
    text-decoration: none;
    color: inherit;
  }//a
  img{
    vertical-align: middle;
  }
  @media screen and (min-width: $minWidth) {
    font-size: 11px;
  }
}

i{
  font-style: normal;
  opacity: 0;
  &.show{
    opacity: 1;
  }
}

table{
  th{
    font-weight: normal;
    text-align: left;
  }
}

.lozad-container{
  position: relative;
  height: 0;
  >img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease .3s;
    opacity: 0;
    &[data-loaded="true"]{
      opacity: 1;
    }
  }//img
}//.lozad-container

@mixin sp {
  @media screen and (max-width: $minWidth) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: #{$minWidth + 1}) {
    @content;
  }
}

@mixin minBp1 {
  @media screen and (min-width: #{$minWidth + 1}) and (max-width: #{$bp1 - 1}){
    @content;
  }
}

@mixin overBp1{
  @media screen and (min-width: $bp1){
    @content;
  }
}

@mixin blend-mode-difference {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  mix-blend-mode: difference;
  /* for IE10 & 11 */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    color: #000;
    text-shadow:1px 1px 0px #fff;
  }
  /* for Microsoft Edge */
  @supports (-ms-ime-align:auto) {
    color: #000;
    text-shadow:1px 1px 0px #fff;
  }
}

@mixin middle($vmpos) {
  position: $vmpos;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@mixin center($hspos) {
  position: $hspos;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
@mixin centerMiddle($cmpos) {
  position: $cmpos;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}


.pc{
  @include sp{
    display: none;
  }
}
.sp{
  @include pc{
    display: none;
  }
}
