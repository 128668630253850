/* noto-sans-jp-regular - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'),
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.woff') format('woff'), /* Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-500 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Medium'), local('NotoSansJapanese-Medium'),
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.woff') format('woff'), /* Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
/* noto-sans-jp-700 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Japanese Bold'), local('NotoSansJapanese-Bold'),
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.woff') format('woff'), /* Modern Browsers */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

::selection {
  background-color: rgba(0,0,0,0.99);
  color: rgba(150,150,150,0.99);
}
body{
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", arial, Verdana, Roboto, "Droid Sans", 'Noto Sans JP',sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  text-transform: uppercase;
  letter-spacing: 0.02em;
  .intro{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    width: 100%;
    visibility: visible;
    opacity: 1;
    transition: visibility .3s ease, opacity .3s ease;
    @include pc{
      height: 100vh;
    }
    &.hide{
      visibility: hidden;
      opacity: 0;
    }
    &__inner{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &__logo{
        width: 170px;
        height: auto;
        vertical-align: middle;
        .char{
          opacity: 0;
          &.show{
            opacity: 1;
          }
        }//.char
      }//&__logo
    }//&__inner
  }//.intro
  #wrapper{
    background: #fff;
    >header{
      //letter-spacing: 0;
      &.movie{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        mix-blend-mode: difference;
        .header_inner{
          .sitename{
            color: #fff;
            &::after{
              background: #fff;
            }//&::after
          }//.sitename
          .menu-wrap{
            ul#menu{
              color: #fff;
            }//ul#menu
          }//.menu-wrap
        }//.header_inner
      }//&.movie
      .header_inner{
        width: 100%;
        position: relative;
        @include pc{
          height: 200px;
        }
        @include sp{
          height: calc(230 / 375 * 100vw);
        }
        .sitename{
          position: absolute;
          display: flex;
          align-items: center;
          @include pc{
            top: 25px;
            left: 30px;
          }
          @include sp{
            padding-top: calc(26 / 375 * 100vw);
            padding-left: calc(15 / 375 * 100vw);
          }
          >p{
            line-height: 1em;
            @include pc{
              font-size: 18px;
            }
            @include sp{
              font-size: calc(22 / 375 * 100vw);
            }
            a{
              @include pc{
                opacity: 1;
                transition: opacity .15s ease;
                &:hover{
                  opacity: .5;
                }
              }
            }//a
          }//p
          &::after{
            content: "";
            display: inline-block;
            background: #000;
            width: 1px;
            animation: blink 2s linear infinite;
            @include pc{
              height: 26px;
              margin-left: 10px;
            }
            @include sp{
              height: calc(26 / 375 * 100vw);
              margin-left: calc(10 / 375 * 100vw);
            }
          }//&::after
          @keyframes blink{
            0% {opacity:0;}
            24% {opacity:0;}
            25% {opacity:1;}
            75% {opacity:1;}
            76% {opacity:0;}
            100% {opacity:0;}
          }
        }//.sitename
        .menu-wrap{
          position: absolute;
          display: flex;
          @include pc{
            top: 30px;
            right: 30px;
          }
          @include sp{
            flex-direction: column-reverse;
            align-items: flex-end;
            bottom: calc(15 / 375 * 100vw);
            right: calc(15 / 375 * 100vw);
          }
          ul#menu{
            display: flex;
            @include pc{
              margin-right: 110px;
            }
            @include sp{
              flex-direction: column;
              align-items: flex-end;
              //padding-top: calc(40 / 375 * 100vw);
              //padding-right: calc(20 / 375 * 100vw);
            }
            >li{
              @include pc{
                margin-left: 30px;
                opacity: 1;
                transition: opacity .15s ease;
                &:hover{
                  opacity: .5;
                }
              }
              &.active{
                color: #939393;
              }
              >a{
                line-height: 1em;
                @include pc{
                  font-size: 18px;
                }
                @include sp{
                  font-size: calc(22 / 375 * 100vw);
                }
              }//a
              &+li{
                @include sp{
                  margin-top: calc(1 / 375 * 100vw);
                }
              }
            }//li
          }//ul.menu
          .header_logo{
            position: fixed;
            z-index: 3;
            mix-blend-mode: difference;
            @include pc{
              top: 30px;
              right: 30px;
              width: 70px;
              opacity: 1;
              transition: opacity .15s ease;
              &:hover{
                opacity: .5;
              }
            }
            @include sp{
              width: calc(72 /  375 * 100vw);
              top: calc(30 / 375 * 100vw);
              right: calc(15 / 375 * 100vw);
            }
            >a{
              >svg{
                width: 100%;
                height: auto;
                fill: #fff;
              }
            }//a
          }//.header_logo
        }//.menu-wrap
      }//.header_inner
    }//header
    >footer{
      line-height: 1.3em;
      &.movie{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 2;
        mix-blend-mode: difference;
        color: #fff;
      }//&.movie
      @include pc{
        padding: 100px 20px 20px;;
        font-size: 10px;
      }
      @include sp{
        padding: calc(90 / 375 * 100vw) calc(15 / 375 * 100vw) calc(30 / 375 * 100vw);
      }
      >ul{
        margin-top: 1em;
      }
      p.copy{
        margin-top: 1em;
        color: #939393;
      }
    }//footer
  }//#wrapper
}//body
