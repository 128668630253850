main#about{
  text-transform: none;
  width: 100%;
  @include pc{
    max-width: 1024px;
    padding: 0 72px;
    margin: 0 auto;
  }
  .about_image{
    width: 100%;
    @include pc{
      margin-bottom: 50px;
    }
    @include sp{
      margin-bottom: calc(50 / 375 * 100vw);
    }
  }//.about_image
  >section{
    font-weight: 500;
    @include sp{
      padding: 0 calc(20 / 375 * 100vw);
    }
    &+section{
      @include pc{
        margin-top: 100px;
      }
      @include sp{
        margin-top: calc(100 / 375 * 100vw);
      }
    }
    >p{
      letter-spacing: 0.06em;
      line-height: 2em;
      @include pc{
        width: 554px;
        font-size: 12px;
      }
      @include sp{
        font-size: calc(13 / 375 * 100vw);
      }
    }
    >p+p{
      margin-top: 1em;
    }
    >ul.sns{
      display: flex;
      justify-content: flex-end;
      @include pc{
        margin-top: -16px;
      }
      @include sp{
        margin-top: calc(20 / 375 * 100vw);
      }
      >li{
        @include pc{
          margin-left: 20px;
          opacity: 1;
          transition: opacity .15s ease;
          &:hover{
            opacity: .5;
          }
        }
        @include sp{
          margin-left: calc(20 / 375 * 100vw);
        }
        >a{
          >img{
            width: auto;
            vertical-align: middle;
            @include pc{
              height: 16px;
            }
            @include sp{
              height: calc(16/ 375 * 100vw);
            }
          }//img
        }//a
      }//li
    }//ul.sns
    >table{
      line-height: 2em;
      @include pc{
        font-size: 12px;
      }
      @include sp{
        font-size: calc(13 / 375 * 100vw);
      }
      th{
        @include pc{
          padding-right: 30px;
        }
        @include sp{
          padding-right: calc(30/ 375 * 100vw);
        }
      }
    }//table
    .map{
      @include pc{
        width: 554px;
        height: 330px;
        margin-top: 20px;
      }
      @include sp{
        width: 100%;
        height: calc((335 / 4 * 3) / 375 * 100vw);
        margin-top: calc(15 / 375 * 100vw);
      }
      >iframe{
        filter: grayscale(100%);
        width: 100%;
        height: 100%;
      }//img
    }//.map
    &.statement{
      @include sp{
        margin-bottom: calc(100 / 375 * 100vw);
      }
      &::after{
        content: "";
        display: block;
        background: #000;
        @include pc{
          width: 30px;
          height: 1px;
          margin-top: 100px;
        }
        @include sp{
          width: calc(30 / 375 * 100vw);
          height: 1px;
          margin-top: calc(100 / 375 * 100vw);
        }
      }
      >p{
        font-weight: 700;
        @include pc{
          font-size: 14px;
        }
        @include sp{
          font-size: calc(14 / 375 * 100vw);
        }
      }//p
    }//&.statement
  }//section
}//main#about
