/*!
Theme Name: tm-inc
Description: tm-inc
*/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

*, *:before, *:after {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-size: 10px;
}

body a {
  text-decoration: none;
  color: inherit;
}

body img {
  vertical-align: middle;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 11px;
  }
}

i {
  font-style: normal;
  opacity: 0;
}

i.show {
  opacity: 1;
}

table th {
  font-weight: normal;
  text-align: left;
}

.lozad-container {
  position: relative;
  height: 0;
}

.lozad-container > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease .3s;
  opacity: 0;
}

.lozad-container > img[data-loaded="true"] {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none;
  }
}

/* noto-sans-jp-regular - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.eot");
  /* IE9 Compat Modes */
  src: local("Noto Sans Japanese Regular"), local("NotoSansJapanese-Regular"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.eot?#iefix") format("embedded-opentype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.woff2") format("woff2"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.woff") format("woff"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.ttf") format("truetype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-regular.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}

/* noto-sans-jp-500 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.eot");
  /* IE9 Compat Modes */
  src: local("Noto Sans Japanese Medium"), local("NotoSansJapanese-Medium"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.eot?#iefix") format("embedded-opentype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.woff2") format("woff2"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.woff") format("woff"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.ttf") format("truetype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-500.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}

/* noto-sans-jp-700 - latin_japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.eot");
  /* IE9 Compat Modes */
  src: local("Noto Sans Japanese Bold"), local("NotoSansJapanese-Bold"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.eot?#iefix") format("embedded-opentype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.woff2") format("woff2"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.woff") format("woff"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.ttf") format("truetype"), url("/wp/wp-content/themes/tm-inc/_assets/font/noto-sans-jp-v25-latin_japanese-700.svg#NotoSansJP") format("svg");
  /* Legacy iOS */
}

::selection {
  background-color: rgba(0, 0, 0, 0.99);
  color: rgba(150, 150, 150, 0.99);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", arial, Verdana, Roboto, "Droid Sans", 'Noto Sans JP',sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

body .intro {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: visibility .3s ease, opacity .3s ease;
}

@media screen and (min-width: 769px) {
  body .intro {
    height: 100vh;
  }
}

body .intro.hide {
  visibility: hidden;
  opacity: 0;
}

body .intro__inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .intro__inner__logo {
  width: 170px;
  height: auto;
  vertical-align: middle;
}

body .intro__inner__logo .char {
  opacity: 0;
}

body .intro__inner__logo .char.show {
  opacity: 1;
}

body #wrapper {
  background: #fff;
}

body #wrapper > header.movie {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  mix-blend-mode: difference;
}

body #wrapper > header.movie .header_inner .sitename {
  color: #fff;
}

body #wrapper > header.movie .header_inner .sitename::after {
  background: #fff;
}

body #wrapper > header.movie .header_inner .menu-wrap ul#menu {
  color: #fff;
}

body #wrapper > header .header_inner {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner {
    height: calc(230 / 375 * 100vw);
  }
}

body #wrapper > header .header_inner .sitename {
  position: absolute;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .sitename {
    top: 25px;
    left: 30px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .sitename {
    padding-top: calc(26 / 375 * 100vw);
    padding-left: calc(15 / 375 * 100vw);
  }
}

body #wrapper > header .header_inner .sitename > p {
  line-height: 1em;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .sitename > p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .sitename > p {
    font-size: calc(22 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .sitename > p a {
    opacity: 1;
    transition: opacity .15s ease;
  }
  body #wrapper > header .header_inner .sitename > p a:hover {
    opacity: .5;
  }
}

body #wrapper > header .header_inner .sitename::after {
  content: "";
  display: inline-block;
  background: #000;
  width: 1px;
  animation: blink 2s linear infinite;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .sitename::after {
    height: 26px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .sitename::after {
    height: calc(26 / 375 * 100vw);
    margin-left: calc(10 / 375 * 100vw);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

body #wrapper > header .header_inner .menu-wrap {
  position: absolute;
  display: flex;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .menu-wrap {
    top: 30px;
    right: 30px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .menu-wrap {
    flex-direction: column-reverse;
    align-items: flex-end;
    bottom: calc(15 / 375 * 100vw);
    right: calc(15 / 375 * 100vw);
  }
}

body #wrapper > header .header_inner .menu-wrap ul#menu {
  display: flex;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu {
    margin-right: 110px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu > li {
    margin-left: 30px;
    opacity: 1;
    transition: opacity .15s ease;
  }
  body #wrapper > header .header_inner .menu-wrap ul#menu > li:hover {
    opacity: .5;
  }
}

body #wrapper > header .header_inner .menu-wrap ul#menu > li.active {
  color: #939393;
}

body #wrapper > header .header_inner .menu-wrap ul#menu > li > a {
  line-height: 1em;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu > li > a {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu > li > a {
    font-size: calc(22 / 375 * 100vw);
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .menu-wrap ul#menu > li + li {
    margin-top: calc(1 / 375 * 100vw);
  }
}

body #wrapper > header .header_inner .menu-wrap .header_logo {
  position: fixed;
  z-index: 3;
  mix-blend-mode: difference;
}

@media screen and (min-width: 769px) {
  body #wrapper > header .header_inner .menu-wrap .header_logo {
    top: 30px;
    right: 30px;
    width: 70px;
    opacity: 1;
    transition: opacity .15s ease;
  }
  body #wrapper > header .header_inner .menu-wrap .header_logo:hover {
    opacity: .5;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > header .header_inner .menu-wrap .header_logo {
    width: calc(72 /  375 * 100vw);
    top: calc(30 / 375 * 100vw);
    right: calc(15 / 375 * 100vw);
  }
}

body #wrapper > header .header_inner .menu-wrap .header_logo > a > svg {
  width: 100%;
  height: auto;
  fill: #fff;
}

body #wrapper > footer {
  line-height: 1.3em;
}

body #wrapper > footer.movie {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  mix-blend-mode: difference;
  color: #fff;
}

@media screen and (min-width: 769px) {
  body #wrapper > footer {
    padding: 100px 20px 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  body #wrapper > footer {
    padding: calc(90 / 375 * 100vw) calc(15 / 375 * 100vw) calc(30 / 375 * 100vw);
  }
}

body #wrapper > footer > ul {
  margin-top: 1em;
}

body #wrapper > footer p.copy {
  margin-top: 1em;
  color: #939393;
}

main#index {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

main#index iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

@media screen and (min-width: 769px) {
  main#archive > ul {
    padding-left: 29px;
    padding-right: 30px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px) {
  main#archive > ul > li {
    width: calc(100% / 3);
    padding: 0 0 0 1px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  main#archive > ul > li:not(:first-of-type) {
    margin-top: calc(50 / 375 * 100vw);
  }
}

main#archive > ul > li:hover > a .thumb-wrap {
  opacity: .5;
}

main#archive > ul > li:hover > a > h2, main#archive > ul > li:hover > a h3, main#archive > ul > li:hover > a h4 {
  opacity: .5;
}

main#archive > ul > li > a .thumb-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: 1;
  transition: opacity .15s ease;
}

main#archive > ul > li > a .thumb-wrap .thumb {
  width: 100%;
  height: 0;
  padding-bottom: calc(2 / 3 * 100%);
  background: no-repeat center/cover;
}

main#archive > ul > li > a .thumb-wrap .mosaic {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

main#archive > ul > li > a .thumb-wrap .mosaic > svg {
  width: 100%;
  height: auto;
}

main#archive > ul > li > a .thumb-wrap .mosaic > svg rect {
  opacity: 1;
}

main#archive > ul > li > a .thumb-wrap .mosaic > svg rect.hide {
  opacity: 0;
}

main#archive > ul > li > a > h2, main#archive > ul > li > a h3, main#archive > ul > li > a h4 {
  opacity: 1;
  transition: opacity .15s ease;
}

main#archive > ul > li > a > h2 {
  line-height: 1em;
}

@media screen and (min-width: 769px) {
  main#archive > ul > li > a > h2 {
    font-size: 18px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  main#archive > ul > li > a > h2 {
    margin: calc(5 / 375 * 100vw) calc(15 / 375 * 100vw) 0;
    font-size: calc(22 / 375 * 100vw);
  }
}

main#archive > ul > li > a > h3 {
  line-height: 1em;
  color: #939393;
}

@media screen and (min-width: 769px) {
  main#archive > ul > li > a > h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  main#archive > ul > li > a > h3 {
    margin: 0 calc(15 / 375 * 100vw);
    font-size: calc(22 / 375 * 100vw);
  }
}

main#archive > ul > li > a > h4 {
  line-height: 1em;
  color: #939393;
}

@media screen and (min-width: 769px) {
  main#archive > ul > li > a > h4 {
    font-size: 10px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  main#archive > ul > li > a > h4 {
    margin: calc(10 / 375 * 100vw) calc(15 / 375 * 100vw) 0;
    font-size: calc(10 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#single > article {
    width: 100%;
    max-width: 1024px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 769px) {
  main#single > article .columns:not(:first-of-type) {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  main#single > article .columns:not(:first-of-type) {
    margin-top: calc(40 / 375 * 100vw);
  }
}

main#single > article .columns .img {
  overflow: hidden;
}

main#single > article .column1 .img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  main#single > article .column2 {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 769px) {
  main#single > article .column2 .img {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  main#single > article .column2 .img:not(:first-of-type) {
    margin-top: calc(40 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#single .overviews {
    width: 100%;
    max-width: 1024px;
    margin: 30px auto 0;
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  main#single .overviews {
    margin-top: calc(40 / 375 * 100vw);
    padding: 0 calc(15 / 375 *100vw);
  }
}

main#single .overviews > h1 {
  line-height: 1.3em;
}

@media screen and (min-width: 769px) {
  main#single .overviews > h1 {
    font-size: 10px;
  }
}

main#single .overviews > table {
  line-height: 1.3em;
  margin-top: 1em;
  text-align: left;
  vertical-align: top;
}

@media screen and (min-width: 769px) {
  main#single .overviews > table {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  main#single .overviews > table {
    font-size: calc(10 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#single .overviews > table tr th {
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  main#single .overviews > table tr th {
    padding-right: calc(30 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#single .overviews > table tr + tr th, main#single .overviews > table tr + tr td {
    padding-top: 4px;
  }
}

@media screen and (max-width: 768px) {
  main#single .overviews > table tr + tr th, main#single .overviews > table tr + tr td {
    padding-top: calc(4 / 375 * 100vw);
  }
}

main#about {
  text-transform: none;
  width: 100%;
}

@media screen and (min-width: 769px) {
  main#about {
    max-width: 1024px;
    padding: 0 72px;
    margin: 0 auto;
  }
}

main#about .about_image {
  width: 100%;
}

@media screen and (min-width: 769px) {
  main#about .about_image {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  main#about .about_image {
    margin-bottom: calc(50 / 375 * 100vw);
  }
}

main#about > section {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  main#about > section {
    padding: 0 calc(20 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#about > section + section {
    margin-top: 100px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section + section {
    margin-top: calc(100 / 375 * 100vw);
  }
}

main#about > section > p {
  letter-spacing: 0.06em;
  line-height: 2em;
}

@media screen and (min-width: 769px) {
  main#about > section > p {
    width: 554px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > p {
    font-size: calc(13 / 375 * 100vw);
  }
}

main#about > section > p + p {
  margin-top: 1em;
}

main#about > section > ul.sns {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 769px) {
  main#about > section > ul.sns {
    margin-top: -16px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > ul.sns {
    margin-top: calc(20 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#about > section > ul.sns > li {
    margin-left: 20px;
    opacity: 1;
    transition: opacity .15s ease;
  }
  main#about > section > ul.sns > li:hover {
    opacity: .5;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > ul.sns > li {
    margin-left: calc(20 / 375 * 100vw);
  }
}

main#about > section > ul.sns > li > a > img {
  width: auto;
  vertical-align: middle;
}

@media screen and (min-width: 769px) {
  main#about > section > ul.sns > li > a > img {
    height: 16px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > ul.sns > li > a > img {
    height: calc(16/ 375 * 100vw);
  }
}

main#about > section > table {
  line-height: 2em;
}

@media screen and (min-width: 769px) {
  main#about > section > table {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > table {
    font-size: calc(13 / 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#about > section > table th {
    padding-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section > table th {
    padding-right: calc(30/ 375 * 100vw);
  }
}

@media screen and (min-width: 769px) {
  main#about > section .map {
    width: 554px;
    height: 330px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section .map {
    width: 100%;
    height: calc((335 / 4 * 3) / 375 * 100vw);
    margin-top: calc(15 / 375 * 100vw);
  }
}

main#about > section .map > iframe {
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  main#about > section.statement {
    margin-bottom: calc(100 / 375 * 100vw);
  }
}

main#about > section.statement::after {
  content: "";
  display: block;
  background: #000;
}

@media screen and (min-width: 769px) {
  main#about > section.statement::after {
    width: 30px;
    height: 1px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section.statement::after {
    width: calc(30 / 375 * 100vw);
    height: 1px;
    margin-top: calc(100 / 375 * 100vw);
  }
}

main#about > section.statement > p {
  font-weight: 700;
}

@media screen and (min-width: 769px) {
  main#about > section.statement > p {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  main#about > section.statement > p {
    font-size: calc(14 / 375 * 100vw);
  }
}
